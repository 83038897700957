export default {
  APP: "APP",
  CUSTOMERS: "CUSTOMERS",
  // SUPPLIERS: "SUPPLIERS",
  // CONTACTS: "CONTACTS",
  // PRODUCTS: "PRODUCTS",
  // FILES: "FILES",
  // FOLDERS: "FOLDERS",
  // LOCATIONS: "LOCATIONS",
  ORGANIZATIONS: "ORGANIZATIONS",
  OPTIONS: "OPTIONS",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  PACKAGES: "PACKAGES",
  RESELLERS: "RESELLERS",
  USERS: "USERS",
  ROLES: "ROLES",
  PERMISSIONS: "PERMISSIONS",
  LOGS: "LOGS",
  PURCHASES_ORDERS: "PURCHASES_ORDERS",
  PURCHASES_INVOICES: "PURCHASES_INVOICES",
  PURCHASES_DELIVERIES: "PURCHASES_DELIVERIES",
  PURCHASES_PAYMENTS: "PURCHASES_PAYMENTS",
  SALES_ORDERS: "SALES_ORDERS",
  SALES_INVOICES: "SALES_INVOICES",
  SALES_DELIVERIES: "SALES_DELIVERIES",
  SALES_PAYMENTS: "SALES_PAYMENTS",
  RESELLER_INVOICES: "RESELLER_INVOICES",
  RESELLER_PAYMENTS: "RESELLER_PAYMENTS",
  RESELLER_PRODUCTS: "RESELLER_PRODUCTS",
  RESELLER_SERVICES: "RESELLER_SERVICES",
  WAREHOUSES: "WAREHOUSES",
  STOCK_MOVEMENTS: "STOCK_MOVEMENTS",
  IMPORTS: "IMPORTS",
  PROPERTIES: "PROPERTIES",
  AUCTIONS: "AUCTIONS",
  PROPERTY_TRANSACTIONS: "PROPERTY_TRANSACTIONS",
  SPOTS: "SPOTS",
  PRICING_RULES: "PRICING_RULES",
  BOOKINGS: "BOOKINGS",
};
